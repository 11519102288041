

.image-container img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
  }
  
  .image-container img.active {
    opacity: 1;
  }
  
  @keyframes image-animation {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    33.33% {
      opacity: 1;
    }
    53.33% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}

