
/* Attribute Analysis Swiper */

.swiper {
  width: 240px;
  height: 320px;
}


.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

/* .swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}

.swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
} */


/* Animated Header : Try It Now */

.animatedHeader {
    color: #00c9db;
    font-size: 3rem;
    text-shadow: 0 0 0 rgba(30, 184, 211, 0.5); /* Initial shadow color */
    animation: shadow-pulse 2s infinite;
}
  
  @keyframes shadow-pulse {
    0% {
      text-shadow: 0 0 30px rgba(30, 184, 211, 0.5);
    }
    50% {
      text-shadow: 0 0 30px rgba(30, 184, 211, 0.8); /* Shallow shadow color */
    }
    100% {
      text-shadow: 0 0 30px rgba(30, 184, 211, 0.5);
    }
}

