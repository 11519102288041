
.uppy-Webcam-buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.uppy-Webcam-button:not(.uppy-Webcam-button--picture) {
    display: none;
}

.uppy-Webcam-permissonsIcon { 
    display: flex;
    justify-content: center;
}

.uppy-Webcam-title {
    text-align: center;
    margin-top: 15px;
}
